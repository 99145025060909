@import "../../global.scss";

.boxWrapper {
  position: relative;
  display: flex;
}

.boxItem {
  flex: 1;
  display: flex;
  background-color: $box-color;
  cursor: pointer;
  color: white;
  &.disabled {
    background-color: white;
  }
}

.appLauncherContainer {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $dashborad-bg-color;
  .divider {
    border-bottom: 2px solid white;
    margin: 20px 0;
    margin-left: 10px;
    width: 100px;
  }
  .headerWrapper {
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: $menu-color;
  }
  .layout {
    flex: 1;
  }
}

.avatarTitle {
  color: white;
  margin: 0px;
  margin-left: 10px;
}

.avatarSubTitle {
  color: #bfbfbf;
  margin: 0;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 3px;
}

.btn {
  display: flex;
  cursor: pointer;
  padding: 10px;
  background-color: #5b5f5e;
  transition: all 0.3s;
  svg {
    fill: white;
  }
  &:hover {
    background-color: #a0a4a9;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.cardContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  .cardDescription {
    display: flex;
    flex: 1;

    align-items: center;
  }
  a {
    text-decoration: none;
  }
}

.iconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;

  svg {
    fill: white;
  }

  .day {
    position: absolute;
    color: white;
    font-size: large;
    font-weight: bold;
    top: 15px;
    z-index: 10;
  }
}

.disabled {
  svg {
    fill: rgb(148, 141, 141);
  }
  .cardTitle {
    color: rgb(148, 141, 141);
  }

  .cardSubtitle {
    color: rgb(148, 141, 141);
  }
}

.grow {
  transition: all 0.2s ease-in-out;
  // &:hover {
  //   transform: scale(1.03);
  // }
}

.cardTitle {
  color: black;
  font-weight: bold;
  font-size: 28px;
}

.cardSubtitle {
  color: black;
  font-size: 20px;
}

.messageCount {
  font-size: 24px;
  margin-left: 5px;
}
.msgCardTitle {
  color: white;
  font-size: 12px;
}

.footer {
  color: white;
  font-size: 12px;
  padding: 10px;
  align-self: flex-end;
}
