$dashborad-bg-color: #333333;
$box-color: #a819bb;
$menu-color: #0c1331;

body {
  // background-color: $dashborad-bg-color;
}
.layout {
  flex: 1;
}
a {
  text-decoration: none;
}
